<template>
    <Header/>
    <Cloudheader/>
    <Cloudservice/>
    <Mobileclient/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Cloudservice from '@/components/Cloudservice.vue'
import Mobileclient from '@/components/mobileappclient.vue'
import Cloudheader from '@/components/cloudheader.vue'

export default {
  components: {
     Header,
    Footer,
    Cloudservice,
    Mobileclient,
    Cloudheader
  }
}
</script>