<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Cloud Computing</h2>
          <p style="padding-top:20px">With the large number of information available on our fingertips, it is important that creators of such information keep it in check. This is possible by Cloud Computing where we offer our clients the assurance that their data can be safely accessed and produced in a logical way. It is our goal to provide the maximum results by enabling Agile values and updated AI technologies to mould our data into a constructive and focussed result.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="cloud">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Iaas.jpg',
        header: 'IAAS (INFRASTRUCTURE AS A SERVICE)',
        caption: 'We provide a cost-effective solution for our clients to use and build their business by accessing basic hardware, servers, networking data and storage space on cloud.'
      },
      {
        path: 'services/Paas.jpg',
        header: 'PAAS (PLATFORMS AS A SERVICE)',
        caption: 'We offer businesses, a platform to work on their software and website developments. Our users can choose a specific form of service from a wide range of options to suit their businesses.'
      },
      {
        path: 'services/Saas.jpg',
        header: 'SAAS (SOFTWARE AS A SERVICE)',
        caption: 'A one stop for all your software services storage easily accessed by anyone connected to the cloud. We ensure a hassle-free timely delivery with utmost security.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 30px 10px 30px;
}

.services .icon-box .cloud {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box .cloud {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box .cloud a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
@media (max-width: 998px) and (min-width: 452px)  {
.services .icon-box h4 {
  font-size: 13px !important;
  line-height: 20px;
}
}
</style>